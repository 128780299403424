import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Icon from '../assets/images/blocks/block-1.svg';
import styled from 'styled-components';
import tw from 'twin.macro';
import media from '../utils/media';

const MaxWidth = styled.div`
  ${tw`mx-auto`}
  max-width:724px;

  .icony {
    max-width: 60px;
    ${media.greaterThan('md')`
      max-width: 97px;
    `}
  }
`;
export default class Legal extends Component {
  render() {
    const { seo, title, content } = this.props.data.wpPage;

    return (
      <Layout>
        <SEO seo={seo} />

        <section className="pad-t--xs">
          <div className="container">
            <MaxWidth>
              <img src={Icon} alt="Icon" className="icony" />
              <h1 className="text-58px font-semibold my-8 md:mb-6" dangerouslySetInnerHTML={{ __html: title }} />
            </MaxWidth>
          </div>
        </section>
        <section className="pad-b pt-8">
          <div className="container">
            <MaxWidth>
              <div className="post-content" dangerouslySetInnerHTML={{ __html: content }} />
            </MaxWidth>
          </div>
        </section>
      </Layout>
    );
  }
}

export const query = graphql`
  query Legal($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      ...SEO
    }
  }
`;
